import React from "react";
import { IonIcon } from "@ionic/react";
import { cloudOfflineOutline } from "ionicons/icons";

interface BannerProps {
  text: string;
}

const Banner: React.FC<BannerProps> = ({ text }) => {
  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 ">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-orange shadow-lg sm:p-3 max-w-4xl m-auto">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-orange-800">
                <IonIcon
                  icon={cloudOfflineOutline}
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span>{text}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
