import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as firebase from "firebase/app";
import activationCodeAX1800 from "assets/images/activation-code-AX1800.png";
import activationCodeAX3000 from "assets/images/activation-code-AX3000.png";

import { Dispatch } from "../../store";
import PageLayout from "../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";
import { ReactComponent as IconProgress } from "../../assets/images/svg-icons/progress.svg";
import { useHistory } from "react-router";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { createPollAndRefresh, tryCatch } from "utils";
import { UnassociatedAdapter, UpdatingAdapter } from "model";
import api from "api";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import InformationMessage from "components/InformationMessage";
import Input from "components/Input";
import Button from "components/Button";

const ActivateContainer: React.FC<{}> = () => {
  const [findingRouter, setFindingRouter] = useState<boolean>(false);
  const [firmwareUpdate, setFirmwareUpdate] = useState<boolean>(false);
  const [manualReboot, setManualReboot] = useState<boolean>(false);
  const [claimedRouter, setClaimedRouter] = useState<boolean>(false);

  const [routerId, setRouterId] = useState<string>("");
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();

  function tryClaimOffline(adapter: UnassociatedAdapter) {
    firebase.analytics().logEvent("claim_adapter", { type: "automatic" });
    FirebaseAnalytics.logEvent("claim_adapter", { type: "automatic" });
    var return_val = 200;
    api.adapter
      .claimAdapter(adapter.id)
      .then(async (adapter) => {
        setClaimedRouter(true);
        console.log("after claim", adapter);
        dispatch.notifications.clearNotifications();
        await dispatch.adapter.setAdapter(adapter.data);
        console.log("after claim");
        dispatch.adapter.setOnboardingStage("after-claim");
        window.sessionStorage.setItem("reloadAfterFind", "notReloaded");
        history.push("/onboarding/router-found");
      })
      .catch((error) => {
        if (error?.response) {
          const { status, data } = error.response;
          if (status !== 555) {
            setFindingRouter(false);
            dispatch.ui.setToast({
              type: "error",
              description: t("notFound.details"),
            });
            history.push("/onboarding/get-connected");
          } else {
            return data;
          }
        } else {
          setFindingRouter(false);
          dispatch.ui.setToast({
            type: "error",
            description: t("notFound.details"),
          });
          history.push("/onboarding/get-connected");
        }
      });
    return return_val;
  }

  async function findUpdatingAdaptersForPoll() {
    const result = await tryCatch(
      api.adapter.findUpdatingAdapters(routerId || undefined)
    );
    if (result.error) {
      return [];
    } else {
      return result.result.data;
    }
  }

  function findAndClaimUpdating() {
    findUpdatingAdaptersForPoll().then((adapters) => {
      try {
        if (adapters !== undefined && !claimedRouter) {
          if (adapters[0].hasOwnProperty("id")) {
            tryClaimOffline(adapters![0]);
            setFirmwareUpdate(true);
            return;
          } else if (adapters[0]?.fw_status.manual_reboot) {
            setManualReboot(true);
          } else {
            return;
          }
        } else if (claimedRouter) {
          return;
        }
      } catch (err) {}
    });
  }
  async function showUpdate() {
    const pollInterval = 10000;
    if (!claimedRouter) {
      findAndClaimUpdating();
      setTimeout(async () => showUpdate(), pollInterval);
    }
  }

  function loadAdaptersAndClaim() {
    FirebaseAnalytics.logEvent("init_page", { page: "activate_router" });
    console.log("Running loadAdaptersAndClaim");
    setFindingRouter(true);
    createPollAndRefresh<UpdatingAdapter[]>(
      findUpdatingAdaptersForPoll,
      (data) => data?.length > 0,
      async (status, adapters) => {
        if (adapters?.length === 1) {
          if (adapters![0].hasOwnProperty("id")) {
            var try_claim_return = tryClaimOffline(adapters![0]);
            if (try_claim_return === 555) {
              setFirmwareUpdate(true);
              setFindingRouter(true);
              await showUpdate();
            } else {
              dispatch.ui.setToast({
                type: "error",
                description: t("generic:errors.activationMessage"),
              });
            }
          } else {
            if (adapters[0]?.fw_status.manual_reboot) {
              setManualReboot(true);
              // dispatch.notifications.addNotification({
              //   level: "WARNING",
              //   message:
              //     "Please manually reboot your router to complete the update.",
              //   canDismiss: false,
              // });
            }
            setFirmwareUpdate(true);
            // setFindingRouter(true);
            await showUpdate();
          }
        } else if (
          status === "Failed!" ||
          (status === "Done!" &&
            adapters !== undefined &&
            (!adapters || adapters.length === 0 || adapters.length > 1))
        ) {
          setFindingRouter(false);
          if (routerId) {
            history.push("/onboarding/router-not-found");
          } else {
            dispatch.ui.setToast({
              type: "error",
              description: t("generic:errors.activationMessage"),
            });
          }
        } else if (status === "Done!") {
          setFirmwareUpdate(true);
          await showUpdate();
        }
      },
      process.env.NODE_ENV === "test" ? 0 : 10
    );
  }
  useEffect(() => {
    loadAdaptersAndClaim();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleInput = (e: any) => {
    setRouterId(e.target.value);
  };

  const handleClick = async () => {
    loadAdaptersAndClaim();
  };
  const { t } = useTranslation("onboarding");
  if (firmwareUpdate) {
    if (manualReboot) {
      return (
        <PageLayout testId="activate-screen">
          <OnboardingSteps stage="activate" />
          <Title title={t("activate.manualRebootTitle")} className="mb-4" />

          <div data-testid="router-needsreboot">
            <div className="updating-icon large-general large-top">
              <div className="inner-updating">
                <IconProgress className="animated" id="icon-progess" />
              </div>
            </div>
            <InformationMessage text={t("activate.needsReboot")} />
          </div>
        </PageLayout>
      );
    } else {
      return (
        <PageLayout testId="activate-screen">
          <OnboardingSteps stage="activate" />
          <Title title={t("activate.updateTitle")} className="mb-4" />
          <div data-testid="router-updating">
            <div className="updating-icon large-general large-top">
              <div className="inner-updating">
                <IconProgress className="animated" id="icon-progess" />
              </div>
            </div>
            <InformationMessage text={t("activate.updatingMessage")} />
          </div>
        </PageLayout>
      );
    }
  } else {
    return (
      <PageLayout testId="activate-screen">
        <OnboardingSteps stage="activate" />
        <Title title={t("activate.title")} className="mb-4" />
        {findingRouter ? (
          <div data-testid="looking-for-router">
            <div className="updating-icon large-general large-top">
              <div className="inner-updating">
                <IconProgress className="animated" id="icon-progess" />
              </div>
            </div>
            <InformationMessage text={t("activate.loadingMessage")} />
          </div>
        ) : (
          <div data-testid="activate-router">
            <InformationMessage
              text={t("activate.instructions")}
              className="mb-2"
            />
            <Input
              id="activate-input"
              type="text"
              label={t("activate.codeLabel")}
              placeholder={t("activate.codePlaceholder")}
              helpText={t("activate.codeHelpText")}
              className="mb-2"
              inputProps={{
                value: routerId,
                onChange: handleInput,
                maxLength: 5,
              }}
            />

            <Button
              disabled={routerId.length !== 5}
              type="button"
              onClick={handleClick}
              data-testid="next"
            >
              Next
            </Button>
            <img
              src={activationCodeAX3000}
              alt="activation code on AX3000"
              style={{ marginTop: 30 }}
            />
            <img
              src={activationCodeAX1800}
              alt="activation code on AX1800"
              style={{ marginTop: 30 }}
            />
          </div>
        )}
      </PageLayout>
    );
  }
};

export default ActivateContainer;
